/* global GIT_SHA */

import 'core-js/stable';
import 'regenerator-runtime/runtime';

// 'pk_test_6msa4SvtkIq8Bz2Da590fSHi' // Stripe
// 'sandbox_x6sv9k6m_q3ncjyksrpkmbxdz' // Braintree
// 'ewr1-tmU5RjqEZbXCoEWl3gim29' // Recurly

import './fonts/Barepay/Barepay.scss';
import './scss/application.scss';
import './scss/barepay.scss';

const ravenSetTagsContext = (...args) => {
  if (window.Raven) {
    window.Raven.setTagsContext(...args);
  }
};

const ravenSetUserContext = (...args) => {
  if (window.Raven) {
    window.Raven.setUserContext(...args);
  }
};

const ravenCaptureMessage = (...args) => {
  if (window.Raven) {
    window.Raven.captureMessage(...args);
  }
};

import(/* webpackChunkName: "sentry" */ 'raven-js').then(({ default: Raven }) => {
  const isDev = !!(window.location.hostname === 'pay.lvh.me'
  || window.location.hostname === 'dashboard.test');

  window.Raven = Raven;

  window.Raven
    .config('https://32ceafb2416d415c94c995d678e286d9@sentry.baremetrics.io/5', {
      release: GIT_SHA,
      environment: isDev ? 'development' : 'production',
      whitelistUrls: isDev ? [] : [/baremetrics-dunning\.baremetrics\.com/, /dunning\.baremetrics\.com/, /barepay\.s3-website-us-east-1\.amazonaws\.com/],
      captureUnhandledRejections: true,
      autoBreadcrumbs: true,
      shouldSendCallback(data) {
        if (
          data.extra
        && data.extra.url
        && /(baremetrics|barepay)/.test(data.extra.url) === false
        ) return false;

        if (
          data.extra
        && data.extra.dataTypes
        && data.extra.dataTypes.indexOf('html') !== -1
        ) return false;

        if (window.barepay.params.callback_error) window.barepay.params.callback_error(data);

        return true;
      },
    })
    .install();
}).catch(() => {});

const locale = window.navigator.userLanguage || window.navigator.language;

// Helper function to test version number
function versionCheck(current, required) {
  let satisfied;

  const parsedRequired = String(required).indexOf('.') === -1 ? [required] : required.split('.');
  const parsedCurrent = String(current).indexOf('.') === -1 ? [current] : current.split('.');

  for (let i = 0; i < parsedRequired.length; i += 1) {
    if (parsedRequired[i] === parsedCurrent[i]) {
      satisfied = 0;
    } else if (parsedRequired[i] < parsedCurrent[i]) {
      satisfied = 1;
    } else {
      satisfied = -1;
    }

    if (satisfied === 1 || satisfied === -1) break;
  }

  return satisfied !== -1;
}

// Helper function to get the values from the search string
function parseSearchString() {
  const clean = {};

  let raw = window.location.search.substring(1);
  raw = raw.split('&');

  raw.forEach((d) => {
    const pair = d.split('=');

    if (pair.length === 2) {
      const value = decodeURIComponent(pair[1]);

      if (value === 'true') {
        clean[pair[0]] = true;
      } else if (value === 'false') {
        clean[pair[0]] = false;
      } else {
        clean[pair[0]] = value;
      }
    }
  });

  return clean;
}

window.barepay = window.barepay || {};

window.barepay.load = () => {
  let params = window.barepay ? window.barepay.params : {};
  params = Object.assign(params, parseSearchString());
  params.barepay_demo = params.barepay_demo || (params.customer_oid ? params.customer_oid.match(/CUSTOMER|SUBSCRIPTION|EXAMPLE|TEST/gi) : undefined);

  window.barepay.loading = true;

  // Get stylesheet
  if (params.display !== 'hosted') {
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.href = `${params.assets_url || 'https://baremetrics-dunning.baremetrics.com'}/css/barepay.css`;

    document.head.appendChild(style);
  }

  // Check for jQuery
  if (window.jQuery && versionCheck(window.jQuery.fn.jquery, '1.7')) {
    window.barepay.$ = window.jQuery;
    getCustomerStatus(params);
  } else {
    import(/* webpackChunkName: "jquery" */ 'jquery')
      .then((jQuery) => {
        window.barepay.$ = jQuery.default ? jQuery.default : jQuery;
        getCustomerStatus(params);
      });
  }

  // Check the customer status
  function getCustomerStatus(params) {
    ravenSetTagsContext({ jquery: window.barepay.$.fn.jquery });
    ravenSetUserContext({
      id: params.customer_oid || params.token,
      customer_oid: params.customer_oid,
      token: params.token,
      access_token_id: params.access_token_id,
      provider_public_token: params.provider_public_token,
    });

    // Load in demo mode
    if (params.barepay_demo) {
      params = window.barepay.$.extend({
        company_name: 'Tasty Company',

        logo_url: 'https://logo.clearbit.com/baremetrics.com?size=80',
        title: 'Tasty Company',
        subtitle: 'Update your payment details.',

        card_details_show: true,
        card_details_color: '#FF2C2C',

        button_text: 'Update Credit Card',
        button_color: '#0C81F6',
        button_text_color: '#FFFFFF',

        banner_background_color: '#FF2C2C',
        banner_text_color: '#FFFFFF',
        banner_message: 'Please update your credit card to continue using our services.',
        banner_button_background_color: '#FFFFFF',
        banner_button_text_color: '#FF2C2C',
        banner_button_text: 'Update',

        redirect_url: '#',

        cancel_text: 'Cancel my subscription',
        cancel_text_color: '#0C81F6',
        cancel_url: '#',

        provider_public_token: 'pk_test_6msa4SvtkIq8Bz2Da590fSHi',
        provider: 'stripe',

        braintree_zip: true,
        recurly_address: true,
        recurly_phone: true,
        recurly_vat: true,

        stripe_setup_intents: false,

        customer: {
          last4: 5432,
          email: 'j***@************com',
          expiry: '08/19',
        },
      }, params);

      loadVue(params);
    } else if (params.customer_oid || params.token) {
      // Get the Customer Status
      window.barepay.$.get(`${params.api_url || 'https://dunning.baremetrics.com'}/customer_status`, {
        access_token_id: params.access_token_id,
        customer_oid: params.customer_oid,
        token: params.token,
      }, (res) => {
        params = window.barepay.$.extend(res, params);

        ravenSetTagsContext(params);

        // If display is set to false don't do anything
        if (!params.display) {
          window.barepay.loading = false;
          window.barepay.$('barepay').remove();
          return;
        }

        // Set head title
        if (params.company_name && params.display === 'hosted') {
          document.querySelector('title').text = `${params.company_name} Billing Information`;
        }

        // Tmp fix to support params in a branch that is not live yet.
        // Remove once Recover v3 is live
        if (!params.cancel_text_color) {
          params.cancel_text_color = '#0C81F6';
        }

        if (!params.cancel_text) {
          params.cancel_text = 'Cancel your account';
        }

        // Now load the vue instance
        loadVue(params);
      })
        .fail((err) => {
          const error = err.responseJSON && (err.responseJSON.message || err.responseJSON.error)
            ? err.responseJSON.message || err.responseJSON.error
            : 'Undefined Error';

          window.barepay.$('.major-error').html(error).show();
        });
    } else {
      const host = window.location.hostname.split('.');

      if (host.length >= 3 && params.display === 'hosted') {
        window.barepay.$.get('https://dunning.baremetrics.com/redirect_url', {
          subdomain: host[0],
          domain: window.location.hostname,
        }, (res) => {
          if (res.redirect_url) {
            window.location.assign(res.redirect_url);
            return;
          }

          if (params.redirect_url) {
            window.location.assign(params.redirect_url);
            return;
          }

          window.barepay.$('barepay').remove();
        });
      } else {
        window.barepay.$('barepay').remove();
      }
    }
  }

  // Load styles and vue
  function loadVue(params) {
    window.barepay.display = params.display;

    if (params.display === 'hosted') {
      // Set the favicon
      if (params.logo_url) window.barepay.$('[rel="shortcut icon"], [rel="icon"], [rel="apple-touch-icon"], [rel="apple-touch-icon-precomposed"]').attr('href', params.logo_url);
    }

    // Check for VueJS
    if (window.Vue && versionCheck(window.Vue.version, '2.1.10')) {
      window.barepay.Vue = window.Vue.default ? window.Vue.default : window.Vue;
      getPaymentHTMLTemplate(params);
    } else {
      import(/* webpackChunkName: "vue" */ 'vue/dist/vue.esm.js').then((Vue) => {
        window.barepay.Vue = Vue.default ? Vue.default : Vue;
        getPaymentHTMLTemplate(params);
      });
    }
  }

  // Get the Vue HTML template
  function getPaymentHTMLTemplate(params) {
    const html = require('./html/barepay.html');
    runVue(html, params);
    ravenSetTagsContext({ vue: window.barepay.Vue.version });
  }

  // Build and run the vue template
  function runVue(template, params) {
    // delete window.barepay.params;

    window.barepay.vue = new window.barepay.Vue({
      el: 'barepay',
      template,
      data() {
        const data = {
          banner_button_background_color: params.banner_text_color,
          banner_button_text_color: params.banner_background_color,
          banner_button_text: null,
          card_details_show: null,
          button_text_color: null,
          button_text: null,

          error: false,
          success: false,
          loading: false,
          callback_error: null,

          ...params,
        };

        if (!params.provider) data.provider = 'stripe';

        return data;
      },
      computed: {
        expiry_string() {
          if (!this.customer.expiry) {
            return '';
          }

          const now = new Date();
          const date = this.customer.expiry.split('/');
          const expires = new Date();

          expires.setYear(`20${date[1]}`);
          expires.setMonth(parseInt(date[0], 10) - 1);

          return `${expires < now ? 'Expired' : 'Expires'} ${this.customer.expiry}`;
        },
      },
      mounted() {
        this.generateForm();
        this.checkDisplay();
        ravenSetTagsContext(this.$data);
        window.barepay.loading = false;
      },
      watch: {
        display() {
          this.checkDisplay();
        },
      },
      destroyed() {
        delete window.barepay.display;
        delete window.barepay.vue;

        window.barepay.$(this.$el)
          .after('<barepay>')
          .remove();
      },
      methods: {

        // Generate the correct form for the customer
        generateForm() {
          this.$nextTick(() => {
            switch (this.provider) {
              case 'stripe':
                this.generateStripeForm(() => {
                  this.generateCallback();
                });
                break;
              case 'braintree':
                this.generateBraintreeForm(() => {
                  this.generateCallback();
                });
                break;
              case 'recurly':
                this.generateRecurlyForm(() => {
                  this.generateCallback();
                });
                break;
              default:
                throw new Error('Unsupported provider');
            }
          });
        },
        generateCallback() {
          window.barepay.$('input, .bm-row-input, .recurly-hosted-field, [data-recurly], [data-braintree]').focus((e) => {
            window.barepay.$(e.target).removeClass('error');
          });

          if (this.display === 'hosted' && locale.indexOf('en') === -1) window.barepay.$.getScript('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        },

        generateStripeForm(callback) {
          if (window.Stripe && versionCheck(window.Stripe.version, 3)) {
            this.setStripe(callback);
          } else {
            window.barepay.$.getScript('https://js.stripe.com/v3/', () => {
              this.setStripe(callback);
            });
          }
        },
        setStripe(callback) {
          ravenSetTagsContext({ stripe: window.Stripe.version });

          if (window.Stripe.StripeV3) {
            this.stripe = window.Stripe.StripeV3(this.provider_public_token, {
              stripeAccount: this.stripe_account_id,
            });
          } else {
            this.stripe = window.Stripe(this.provider_public_token, {
              stripeAccount: this.stripe_account_id,
            });
          }

          this.$nextTick(() => {
            const elements = this.stripe.elements();

            this.card = elements.create('card', {
              style: {
                base: {
                  iconColor: this.button_color,
                  color: '#242E35',
                  lineHeight: '42px',
                  fontWeight: 400,
                  fontFamily: "system-ui, -apple-system, Segoe UI, Roboto, 'Noto Sans', Ubuntu, Cantarell, 'Helvetica Neue', Arial, sans-serif",
                  fontSize: '14px',
                  '::placeholder': {
                    color: '#A7A7A7',
                  },
                },
              },
            });

            this.card.mount('#bm-card-element');

            this.card.on('change', () => {
              this.error = false;
            });

            this.card.on('ready', () => {
              callback();
            });
          });
        },

        generateBraintreeForm(callback) {
          import(/* webpackChunkName: "braintree" */ 'braintree-web')
            .then((braintree) => {
              window.braintree = braintree.default ? braintree.default : braintree;
              this.setBraintree(callback);
            });
        },
        setBraintree(callback) {
          window.braintree.client.create({
            authorization: this.provider_public_token,
          }, (err, res) => {
            if (err) {
              this.error = err.message || 'Braintree Client Error';
              ravenCaptureMessage(this.error, { extra: err });
            } else {
              const options = {
                client: res,
                styles: {
                  input: {
                    'font-size': '12px',
                  },
                },
                fields: {
                  number: {
                    selector: '.bm-number',
                  },
                  expirationDate: {
                    selector: '.bm-exp',
                    placeholder: 'MM/YY',
                  },
                  cvv: { selector: '.bm-cvc' },
                },
              };

              if (this.braintree_zip) options.fields.postalCode = { selector: '.bm-zip' };

              window.braintree.hostedFields.create(options, (err, res) => {
                if (err) {
                  this.error = err.message || 'Braintree Fields Error';
                  ravenCaptureMessage(this.error, { extra: err });
                } else {
                  this.hostedFields = res;
                  callback();
                }
              });
            }
          });
        },

        generateRecurlyForm(callback) {
          if (window.recurly) {
            this.setRecurly(callback);
          } else {
            window.barepay.$.getScript('https://js.recurly.com/v4/recurly.js', () => {
              this.setRecurly(callback);
            });
          }
        },
        setRecurly(callback) {
          window.recurly.configure({
            publicKey: this.provider_public_token,
            fields: {
              all: {
                style: {
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontColor: 'black',
                  lineHeight: '1rem',
                  fontWeight: 400,
                  placeholder: {
                    color: '#A7A7A7',
                  },
                },
              },
              month: {
                style: {
                  selector: '.recurly-month',
                  placeholder: {
                    content: 'MM',
                  },
                },
              },
              year: {
                style: {
                  selector: '.recurly-year',
                  placeholder: {
                    content: 'YY',
                  },
                },
              },
              cvv: { selector: '.recurly-cvv' },
            },
          });

          callback();
        },

        // Submit the forms to the correct processor
        submit(e) {
          e.preventDefault();

          this.scopeReset();
          this.loading = true;

          // If we are in demo mode, we don't want to actually trigger any saving to Stripe
          if (this.barepay_demo) {
            this.success = '[DEMO MODE] This form is valid!';
            window.setTimeout(() => {
              this.success = false;
              this.scopeReset();

              if (this.card) {
                this.card.clear();
              }
            }, 3000);
            return;
          }

          switch (this.provider) {
            case 'stripe':
              this.submitStripe();
              break;
            case 'braintree':
              this.submitBraintree();
              break;
            case 'recurly':
              this.submitRecurly();
              break;
            default:
              throw new Error('Unsupported provider');
          }
        },
        submitStripe() {
          this.stripe.createSource(this.card).then((result) => {
            if (result.error) {
              this.loading = false;
              this.error = result.error.message || 'Stripe Source Error';
              ravenCaptureMessage(this.error, { extra: result.error });
              return;
            }

            window.barepay.$.post(`${this.api_url || 'https://dunning.baremetrics.com'}/card_updates/stripe_setup_intent`, {
              token: this.token,
              source: result.source.id,
              access_token_id: this.access_token_id,
              customer_oid: this.customer_oid,
            }, (setupIntentResponse) => {
              this.stripe
                .confirmCardSetup(
                  setupIntentResponse.client_secret,
                  { payment_method: { card: this.card } },
                )
                .then((setupResponse) => {
                  if (setupResponse.error) {
                    this.error = setupResponse.error.message || 'Stripe Handle Setup Error';
                    ravenCaptureMessage(this.error, { extra: setupResponse.error });
                  } else {
                    this.postCardUpdate(setupResponse.setupIntent.payment_method);
                  }
                });
            })
              .fail((err) => {
                this.loading = false;
                this.error = err.responseJSON?.message || err.responseJSON?.error || 'Undefined Card Update Error';

                ravenCaptureMessage(this.error, { extra: err });
              });
          });
        },
        submitBraintree() {
          this.hostedFields.tokenize((err, res) => {
            if (err) {
              this.loading = false;
              this.error = err.message || 'Braintree Token Error';
              ravenCaptureMessage(this.error, { extra: err });

              if (err.details) {
                window.barepay.$.each(err.details.invalidFieldKeys, (i, field) => {
                  window.barepay.$(`[data-braintree="${field}"]`).addClass('error');
                });
              }
            } else {
              this.postCardUpdate(res.nonce);
            }
          });
        },
        submitRecurly() {
          window.recurly.token(window.barepay.$(this.$el).find('form')[0], (err, res) => {
            if (err) {
              this.loading = false;
              this.error = err.message || 'Recurly Token Error';
              ravenCaptureMessage(this.error, { extra: err });

              window.barepay.$.each(err.fields, (i, field) => {
                window.barepay.$(`[data-recurly="${field}"]`).addClass('error');
              });
            } else {
              this.postCardUpdate(res.id);
            }
          });
        },

        // Post the card token back to the dunning API
        postCardUpdate(cardToken) {
          window.barepay.$.post(`${this.api_url || 'https://dunning.baremetrics.com'}/card_updates`, {
            token: this.token,
            card_token: cardToken,
            access_token_id: this.access_token_id,
            customer_oid: this.customer_oid,
          }, () => {
            this.success = 'Card successfully updated. Thank you!';

            window.setTimeout(() => {
              if (this.redirect_url) window.location.assign(this.redirect_url);

              else window.location.reload();
            }, 1000);

            this.scopeReset();
          })
            .fail((err) => {
              this.loading = false;
              this.error = err.responseJSON && (err.responseJSON.message || err.responseJSON.error)
                ? err.responseJSON.message || err.responseJSON.error
                : 'Undefined Card Update Error';

              ravenCaptureMessage(this.error, { extra: err });
            });
        },

        // Reset the form
        scopeReset() {
          this.loading = false;
          this.error = false;
          window.barepay.$('.bm-row-icon', this.$el).removeClass('red green');
          window.barepay.$('input, .bm-row-input, .recurly-hosted-field, [data-recurly], [data-braintree]').removeClass('error');
        },

        // Do we need to limit the height so the modal is in the middle of the page?
        checkDisplay() {
          if (this.display === 'blocker' && (!this.barepay_demo || window.barepay.display === 'blocker')) {
            window.barepay.$('body, html').addClass('bm-squash');

            window.setTimeout(() => {
              window.barepay.$('html, body').animate({
                scrollTop: 0,
              }, 200);
            }, 10);
          } else {
            window.barepay.$('body, html').removeClass('bm-squash');
          }
        },

        // When the root background is clicked, used to close the blocker model in in banner view
        clickBackground(e) {
          if (e.target === this.$el
              && window.barepay.display === 'banner'
              && this.display === 'blocker'
          ) {
            this.display = 'banner';
          }
        },
      },
    });
  }
};

(() => window.barepay.load())();
